@import 'styles/variables';

.seeMore {
  width: 100%;
  margin-top: 40px;
  padding: 16px 0;
  border-radius: 30px;
  border: 2px solid;
  background-color: $orange200;
  color: $white;
  font-size: 36px;
  font-weight: 700;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: darken($orange200, 10%);
  }
  &:disabled {
    background-color: lighten($orange200, 10%);
    cursor: not-allowed;
  }
  @media (max-width: map-get($grid-breakpoints, 'lg')) {
    font-size: 12px;
    border-radius: 20px;
    width: 100%;
    margin-top: 20px;
  }
}
