.wrapper {
  padding-top: 2rem;
  padding-bottom: 1.5rem;

  @media (min-width: 768px) {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 1024px) {
    width: 60%;
  }

  :global(.slick-arrow) {
    display: none !important;
    width: 2rem;
    height: 2rem;

    @media (min-width: 768px) {
      display: block !important;
    }

    &:global(.slick-next) {
      right: -3.5rem;
    }

    &:global(.slick-prev) {
      left: -3.5rem;
    }

    &::before {
      color: black !important;
      font-size: 3rem;
    }
  }

  .article {
    padding: 0 0.75rem;

    .articleImage {
      width: 70%;
      height: auto;
      display: block;
      margin-left: auto;
      margin-right: auto;

      @media (min-width: 768px) {
        width: 60%;
      }
    }

    .imageCaption {
      width: 70%;
      color: #a9acbe;
      font-size: 0.75rem;
      margin: 0.25rem auto 1rem;

      @media (min-width: 768px) {
        width: 60%;
      }
    }

    .articleTitle {
      margin-bottom: 0.5rem;
      font-size: 1.25rem;

      @media (min-width: 768px) {
        font-size: 1.5rem;
      }
    }

    .articleReadMore {
      text-align: right;
    }
  }
}

:global(.darkmode) {
  .wrapper {
    color: white;

    :global(.slick-arrow) {
      &::before {
        color: white !important;
      }
    }

    :global(.slick-dots) {
      li>button::before {
        color: white !important;
      }
    }
  }
}