@import 'styles/variables';

.bannerFreemium {
  display: grid;
  grid-template-columns: 1fr 92px;
  margin: 12px 0 20px;
  padding: 6px 12px;
  position: relative;
  border: 1px solid $newMenuDarkGray; 
  font-family: Inter, sans-serif;

  .imageWrapper {
     grid-column-start: 2;
     position: relative;

     img {
       position: absolute;
       top: -12px;
       width: 100%;
       height: auto;
       transform: scaleX(-1);
     }
   } 


  .content {
    grid-column-start: 1;
    grid-row-start: 1;

    .title {
      font-size: 18px;
      font-weight: 800;
      line-height: 22px;
      color: $premiumDark;
      margin-bottom: 12px;
    }

    .benefits {
      li {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        display: flex;
        gap: 3px;
        align-items: center;
        color: $greytxt;

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        &:before {
          content: '';
          display: block;
          width: 12px;
          height: 12px;
          margin-right: 8px;
          color: $premiumDark;
          background-image: url('/static/svg/tick.svg');
          background-size: cover;
        }

        strong {
          color: $premiumDark;
        }
      }
    }
  }

  .actions {
    display: flex;
    column-gap: 12px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: span 2;
    margin-bottom: 12px;

    .link {
      display: block;
      padding: 8px 12px;;
      flex-grow: 1;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      border-radius: 10px;
      background: #015C92;
      color: white;
      border: 1px solid #015C92; 

      &.outline {
        color: #015C92;
        background-color: white;
      }
    } 

    .or {
      color: $greytxt;
      font-size: 8px;
      line-height: 10px;
    }
  }

  .footer {
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: span 2;
    color: $greytxt;
    font-size: 10px;
    line-height: 12px;

    a {
      text-decoration: underline;
    }

    .textPremium {
      font-weight: 700;
      color: $orange500;
    }
  }
}

.bannerPremium {
  font-family: Inter, sans-serif;
  border: 1px solid $orange900;
  margin: 48px 0 48px 32px;;
  padding: 20px 16px 10px 72px;;
  max-width: 900px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    background: url('/static/premium/banner_premium.png');
    background-size: cover;
    width: 92px;
    height: 112px;
    transform: translateY(-50%);
    top: 50%;
    left: -32px;
  }

  .title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 800;
    margin-bottom: 0;

    .textPremium {
      color: $orange500;
    }
  }

  .subTitle {
    font-size: 10px;
    line-height: 12px;
    color: $greytxt;
    margin-bottom: 12px;
  }

  .thankYou {
    margin-bottom: 0;
    font-size: 10px;
    line-height: 12px;
    font-weight: 800;
    color: $greytxt;
    text-align: right;
  }
}

@media (min-width: map-get($grid-breakpoints, md)) {
   .bannerFreemium {
     max-width: 900px;
     margin: 42px auto;
     padding: 20px 32px;
      grid-template-columns: 300px 1fr;  
      overflow: hidden;
      &:before {
          content: '';
          display: block;
          position: absolute;
          background-color: #b6d7e7;
          height: 100%;
          width: 300px;
          border-radius: 0 50% 50% 0;
          top: 15%;
          left: 0;
        }

      .imageWrapper {
        position: relative;
        grid-column-start: 1;
        grid-column-end: 1;
        grid-row-end: span 3;

        img {
          transform: none;
          position: static;
          width: 80%;
        }
      }

      .content {
        grid-column-start: 2;
        margin-bottom: 16px;

        .title {
          font-size: 24px;
          line-height: 30px;
          max-width: 330px;
          margin-bottom: 20px;
        }

        .benefits {
          li {
            font-size: 14px;
            line-height: 16px;

            &:not(:last-child) {
             margin-bottom: 16px;
            } 

            &:before {
              width: 24px;
              height: 24px;
            }
          }
        }
      }

      .actions {
        grid-column-start: 2;
        grid-column-end: span 1;
        margin-bottom: 16px;

        .link {
          padding: 12px 16px;
          font-size: 14px;
          line-height: 16px;
        }

        .or {
          font-size: 14px;
          line-height: 16px;
        }
      }
      

      .footer {
        grid-column-start: 2;
        grid-column-end: span 1;
        font-size: 14px;
        line-height: 20px;
      }
   } 

   .bannerPremium {
     padding: 40px 32px 20px 178px;
     margin: 64px auto;
     max-width: 600px;

     &:before {
       width: 178px;
       height: 214px;
       left: -64px;
     }


     .title {
       font-size: 40px;
       line-height: 48px;
       margin-bottom: 10px;
     }

     .subTitle {
       font-size: 16px;
       line-height: 20px;
       margin-bottom: 10px;
     }

     .thankYou {
       font-size: 12px;
       line-height: 14px;
     }
   }
}

@media (min-width: map-get($grid-breakpoints, xl)) {
  .bannerPremium {
    max-width: 900px;
  }
}

:global(.darkmode) {
  .bannerFreemium {
    .content .title,
    .content .benefits li,
    .content .benefits li:before,
    .content .benefits li strong,
    .actions .or,
    .footer {
      color: white;
    }

    .actions {
      .link.outline {
        border-color: white;
        background: none;
        color: white;
      }
    }
  }

  .bannerPremium {
    .title, .subTitle, .thankYou {
      color: white;
    }
  }
}
